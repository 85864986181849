import { CeresProjectTypesConfig } from './interfaces/ceres-project-types-config.interface';

// Add new ProjectType in Database Table "project_type"
// Add new config for projecttype here
// Reference User is an employee Id whcih is used for project lead in guest user scenario

const PROJECTTYPES: CeresProjectTypesConfig[] = [
    {
        name: 'Basic',
        config: {
            'new-project.master-data.mpNumber': true,
            'new-project.master-data.title': true,
            'new-project.master-data.description': true,
            'new-project.master-data.comment': true,
            'new-project.master-data.project-lead': true,
            'new-project.master-data.department': true,
            'new-project.master-data.customer': true,
            'new-project.master-data.customer-readOnly': false,
            'new-project.master-data.planned-start': true,
            'new-project.master-data.planned-end': true,
            'new-project.master-data.location': true,
            'new-project.master-data.status': true,
            'new-project.master-data.maturity': true,
            'new-project.master-data.project-space-title': true,
            'new-project.master-data.project-space-link': true,
            'new-project.accounting-info.customer-type': true,
            'new-project.accounting-info.customer-type-readOnly': false,
            'new-project.accounting-info.beneficial': true,
            'new-project.accounting-info.cost-center': false,
            'new-project.accounting-info.cost-center-readOnly': false,
            'new-project.accounting-info.internal-psp-element': true,
            'new-project.accounting-info.customer-psp-element': true,
            'new-project.accounting-info.country-code': true,
            'new-project.accounting-info.currency': true,
            'new-project.accounting-info.contract-type': true,
            'new-project.accounting-info.contract-type-readOnly': false,
            'new-project.accounting-info.accounting-cycle': true,
            'new-project.accounting-info.accounting-cycle-readOnly': false,
            'new-project.accounting-info.invest': false,
            'new-project.accounting-info.foreign-customer': true,
            'new-project.accounting-info.tech-approval': true,
            'new-project.accounting-info.commercial-approval': true,
            'new-project.accounting-info.project-group': true,
            'new-project.accounting-info.project-employees': true,
            'new-project.accounting-info.project-classification': true,
            'new-project.financials.show-templates': true,
            'new-project.financials.readOnly': false,
            'new-project.financials.calculation.cost-per-day': true,
            'new-project.financials.overview.project-lead': true,
            'new-project.financials.overview.planned-costs': true,
            'new-project.financials.overview.margin': true,
            'new-project.financials.overview.planned-price': true,
            'new-project.financials.overview.project-value': true,
            'project.financials.project-value': true,
            'project.financials.project-value-readonly': false,
            'project.financials.order-value': false,
            'project.financials.forecast-charging-volume': false,
            'project.financials.forecast-external-costs': false,
            'project.financials.accounted-value': false,
            'project.financials.proposal-billing': false,
            'project.financials.remaining-budget': true,
            'project.financials.proportional-linear-remaining-budget': false,
            'project.financials.tenant-honorary-planned-costs': false,
            'project.financials.tenant-honorary-planned-price': false,
            'project.financials.tenant-honorary-actual-costs': true,
            'project.financials.tenant-honorary-actual-price': false,
            'project.financials.planned-external-costs': false,
            'project.financials.planned-price-external': false,
            'project.financials.actual-external-costs': false,
            'project.financials.accrued-costs-external': false,
            'project.financials.POC-value': false,
            'project.financials.POC-profit': false,
            'project.financials.POC-delta-allocation': false,
            'project.financials.actual-profit': false,
            'project.financials.travel-costs': false
        },
        referenceEmployee: null
    },
    {
        name: 'Classic',
        config: {
            'new-project.master-data.mpNumber': true,
            'new-project.master-data.title': true,
            'new-project.master-data.description': true,
            'new-project.master-data.comment': true,
            'new-project.master-data.project-lead': true,
            'new-project.master-data.department': true,
            'new-project.master-data.customer': true,
            'new-project.master-data.customer-readOnly': false,
            'new-project.master-data.planned-start': true,
            'new-project.master-data.planned-end': true,
            'new-project.master-data.location': true,
            'new-project.master-data.status': true,
            'new-project.master-data.maturity': true,
            'new-project.master-data.project-space-title': true,
            'new-project.master-data.project-space-link': true,
            'new-project.accounting-info.customer-type': true,
            'new-project.accounting-info.customer-type-readOnly': false,
            'new-project.accounting-info.beneficial': true,
            'new-project.accounting-info.cost-center': false,
            'new-project.accounting-info.cost-center-readOnly': false,
            'new-project.accounting-info.internal-psp-element': true,
            'new-project.accounting-info.customer-psp-element': true,
            'new-project.accounting-info.country-code': true,
            'new-project.accounting-info.currency': true,
            'new-project.accounting-info.contract-type': true,
            'new-project.accounting-info.contract-type-readOnly': false,
            'new-project.accounting-info.accounting-cycle': true,
            'new-project.accounting-info.accounting-cycle-readOnly': false,
            'new-project.accounting-info.invest': false,
            'new-project.accounting-info.foreign-customer': true,
            'new-project.accounting-info.tech-approval': true,
            'new-project.accounting-info.commercial-approval': true,
            'new-project.accounting-info.project-group': true,
            'new-project.accounting-info.project-employees': true,
            'new-project.accounting-info.project-classification': true,
            'new-project.financials.show-templates': true,
            'new-project.financials.readOnly': false,
            'new-project.financials.calculation.cost-per-day': true,
            'new-project.financials.overview.project-lead': true,
            'new-project.financials.overview.planned-costs': true,
            'new-project.financials.overview.margin': true,
            'new-project.financials.overview.planned-price': true,
            'new-project.financials.overview.project-value': true,
            'project.financials.project-value': false,
            'project.financials.project-value-readonly': false,
            'project.financials.order-value': false,
            'project.financials.forecast-charging-volume': false,
            'project.financials.forecast-external-costs': false,
            'project.financials.accounted-value': false,
            'project.financials.proposal-billing': false,
            'project.financials.remaining-budget': true,
            'project.financials.proportional-linear-remaining-budget': false,
            'project.financials.tenant-honorary-planned-costs': false,
            'project.financials.tenant-honorary-planned-price': false,
            'project.financials.tenant-honorary-actual-costs': true,
            'project.financials.tenant-honorary-actual-price': false,
            'project.financials.planned-external-costs': false,
            'project.financials.planned-price-external': false,
            'project.financials.actual-external-costs': false,
            'project.financials.accrued-costs-external': false,
            'project.financials.POC-value': false,
            'project.financials.POC-profit': false,
            'project.financials.POC-delta-allocation': false,
            'project.financials.actual-profit': false,
            'project.financials.travel-costs': false
        },
        referenceEmployee: null
    },
    {
        name: 'Proposal',
        config: {
            'new-project.master-data.mpNumber': true,
            'new-project.master-data.title': true,
            'new-project.master-data.description': true,
            'new-project.master-data.comment': true,
            'new-project.master-data.project-lead': false,
            'new-project.master-data.department': true,
            'new-project.master-data.customer': true,
            'new-project.master-data.customer-readOnly': true,
            'new-project.master-data.planned-start': true,
            'new-project.master-data.planned-end': true,
            'new-project.master-data.location': true,
            'new-project.master-data.status': true,
            'new-project.master-data.maturity': true,
            'new-project.master-data.project-space-title': true,
            'new-project.master-data.project-space-link': true,
            'new-project.accounting-info.customer-type': true,
            'new-project.accounting-info.customer-type-readOnly': true,
            'new-project.accounting-info.beneficial': true,
            'new-project.accounting-info.cost-center': false,
            'new-project.accounting-info.cost-center-readOnly': false,
            'new-project.accounting-info.internal-psp-element': true,
            'new-project.accounting-info.customer-psp-element': true,
            'new-project.accounting-info.country-code': true,
            'new-project.accounting-info.currency': true,
            'new-project.accounting-info.contract-type': true,
            'new-project.accounting-info.contract-type-readOnly': true,
            'new-project.accounting-info.accounting-cycle': true,
            'new-project.accounting-info.accounting-cycle-readOnly': true,
            'new-project.accounting-info.invest': false,
            'new-project.accounting-info.foreign-customer': false,
            'new-project.accounting-info.tech-approval': false,
            'new-project.accounting-info.commercial-approval': false,
            'new-project.accounting-info.project-group': false,
            'new-project.accounting-info.project-employees': false,
            'new-project.accounting-info.project-classification': true,
            'new-project.financials.show-templates': false,
            'new-project.financials.readOnly': false,
            'new-project.financials.calculation.cost-per-day': true,
            'new-project.financials.overview.project-lead': false,
            'new-project.financials.overview.planned-costs': true,
            'new-project.financials.overview.margin': true,
            'new-project.financials.overview.planned-price': true,
            'new-project.financials.overview.project-value': true,
            'project.financials.project-value': true,
            'project.financials.order-value': true,
            'project.financials.forecast-charging-volume': true,
            'project.financials.forecast-external-costs': true,
            'project.financials.accounted-value': true,
            'project.financials.proposal-billing': true,
            'project.financials.remaining-budget': true,
            'project.financials.proportional-linear-remaining-budget': true,
            'project.financials.tenant-honorary-planned-costs': true,
            'project.financials.tenant-honorary-planned-price': true,
            'project.financials.tenant-honorary-actual-costs': true,
            'project.financials.tenant-honorary-actual-price': true,
            'project.financials.planned-external-costs': true,
            'project.financials.planned-price-external': true,
            'project.financials.actual-external-costs': true,
            'project.financials.accrued-costs-external': true,
            'project.financials.POC-value': true,
            'project.financials.POC-profit': true,
            'project.financials.POC-delta-allocation': true,
            'project.financials.actual-profit': true,
            'project.financials.travel-costs': false
        },
        referenceEmployee: 134
    },
    {
        name: 'Preliminary',
        config: {
            'new-project.master-data.mpNumber': false,
            'new-project.master-data.title': false,
            'new-project.master-data.description': false,
            'new-project.master-data.comment': false,
            'new-project.master-data.project-lead': false,
            'new-project.master-data.department': false,
            'new-project.master-data.customer': false,
            'new-project.master-data.customer-readOnly': false,
            'new-project.master-data.planned-start': false,
            'new-project.master-data.planned-end': false,
            'new-project.master-data.location': false,
            'new-project.master-data.status': false,
            'new-project.master-data.maturity': true,
            'new-project.master-data.project-space-title': false,
            'new-project.master-data.project-space-link': false,
            'new-project.accounting-info.customer-type': false,
            'new-project.accounting-info.customer-type-readOnly': false,
            'new-project.accounting-info.beneficial': false,
            'new-project.accounting-info.cost-center': false,
            'new-project.accounting-info.cost-center-readOnly': false,
            'new-project.accounting-info.cost-center-blank': false,
            'new-project.accounting-info.cost-center-notAvailable': false,
            'new-project.accounting-info.country-code': false,
            'new-project.accounting-info.currency': false,
            'new-project.accounting-info.contract-type': false,
            'new-project.accounting-info.contract-type-readOnly': false,
            'new-project.accounting-info.accounting-cycle': false,
            'new-project.accounting-info.accounting-cycle-readOnly': false,
            'new-project.accounting-info.invest': false,
            'new-project.accounting-info.foreign-customer': false,
            'new-project.accounting-info.tech-approval': false,
            'new-project.accounting-info.commercial-approval': false,
            'new-project.accounting-info.project-group': false,
            'new-project.accounting-info.project-employees': false,
            'new-project.accounting-info.project-classification': false,
            'new-project.financials.show-templates': false,
            'new-project.financials.readOnly': false,
            'new-project.financials.calculation.cost-per-day': false,
            'new-project.financials.overview.project-lead': false,
            'new-project.financials.overview.planned-costs': false,
            'new-project.financials.overview.margin': false,
            'new-project.financials.overview.planned-price': false,
            'new-project.financials.overview.project-value': false,
            'project.financials.project-value': false,
            'project.financials.order-value': false,
            'project.financials.forecast-charging-volume': false,
            'project.financials.forecast-external-costs': false,
            'project.financials.accounted-value': false,
            'project.financials.proposal-billing': false,
            'project.financials.remaining-budget': false,
            'project.financials.proportional-linear-remaining-budget': false,
            'project.financials.tenant-honorary-planned-costs': false,
            'project.financials.tenant-honorary-planned-price': false,
            'project.financials.tenant-honorary-actual-costs': false,
            'project.financials.tenant-honorary-actual-price': false,
            'project.financials.planned-external-costs': false,
            'project.financials.planned-price-external': false,
            'project.financials.actual-external-costs': false,
            'project.financials.accrued-costs-external': false,
            'project.financials.POC-value': false,
            'project.financials.POC-profit': false,
            'project.financials.POC-delta-allocation': false,
            'project.financials.actual-profit': false,
            'project.financials.travel-costs': false
        },
        referenceEmployee: 134,
        referenceProjectPreliminary: 4194
    }
];

export default PROJECTTYPES;
