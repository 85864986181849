import { CeresEmployeeConfig } from './interfaces/ceres-employee-config.interface';

const EMPLOYEECONFIG: CeresEmployeeConfig = {
    'employee.employee': true,
    'employee.username': true,
    'employee.username-short': true,
    'employee.are': true,
    'employee.country': true,
    'employee.location': true,
    'employee.contract-type': true,
    'employee.role': true,
    'employee.KFM': true,
    'employee.FK': true,
    'employee.portfolio': true,
    'employee.all-business-areas': true,
    'employee.department': true,
    'employee.corporate-name': true,
    'employee.sollstunden': true,
    'employee.urlaubstage': true,
    'employee.assigned-employees': true,
    'employee.default-activity': true,
    'employee.other-activities': true,
    'employee.internal-psp-element': true
};

export default EMPLOYEECONFIG;
