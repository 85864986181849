export const environment = {
    production: false,
    edgeService: 'https://api.dev.dilearning.ceres.siemens.cloud',
    scdUrl: 'https://api.dev.scd.suites.siemens.cloud',
    siteCollection: '/test/10000120',
    languageAssetsPath: './assets/language/',
    tracking: false,
    whitelist: ['api.dev.dilearning.ceres.siemens.cloud'],
    authentication: {
        unauthorizedRoute: '/unauthorized',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: '6kq5m517btfq2j315o4h039sqo',
        authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
        authWellknownEndpointUrl:
            'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
        scope: 'openid profile email',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: true,
        renewTimeBeforeTokenExpiresInSeconds: 300,
        renewUserInfoAfterTokenRenew: true,
        ignoreNonceAfterRefresh: true
    },
    apiKey: 'M5jryXhjsU329ytkaEwcM9qwoWFFx9Ht1XBU41E8',
    tenant: 'DI CS FA DIL',
    guestAccess: false,
    links: {
        documentOfferTemplate: '',
        documentOfferTemplateEN: ''
    },
    junoApp: 'https://juno.suites.siemens.cloud',
    junoApiURI: 'https://api.mail.wcs-apps.siemens.cloud/v2.0',
    junoAppId: '73a1c4a89ae54505824b6f0f4fcc78b3'
};
